$mobile-max-screen-width: 768px;

$event-catalog-background-image: '/assets/images/default/event-catalog-background.jpg';

$primary-blue: #172535;
$dark-grey: #404040;
$grey: #717171;
$grey-disabled: #BDBDBD;
$light-grey: #E7E7E7;
$lightest-grey: #F1F3F3;
$blue: #0275D8;
$navy: #042E6F;
$violet: #4D046F;
$yellow: #DEB54C;
$dark-yellow: #C59008;
$green: #1F7331;
$dark-red: #812020;
$red: #F44336;
$light-green: #b2b136;
$bright-green: #21d467;
$primary-green: #163832;
$error-red: #D32F2F;

$mui-disabled-color: rgba(0, 0, 0, 0.26);
$mui-disabled-bg-color: rgba(0, 0, 0, 0.12);

$common-xs-padding: 8px;
$common-sm-padding: $common-xs-padding * 2; //16px
$common-md-padding: $common-xs-padding * 3; //24px
$common-lg-padding: $common-xs-padding * 4; //32px
$common-xl-padding: $common-xs-padding * 6; //48px
$form-page-footer-padding: 90px;
$common-transition: all .3s ease-in-out;
$common-card-box-shadow: 0 15px 30px rgba(black, 0.1);

$sidebar-width: 365px;

