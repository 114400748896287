@import 'src/variables';

$disable-theme-classname: 'disable-theme';

$default-header-background-url: '/assets/images/default/header-background.jpeg';
$default-login-background-url: '/assets/images/default/login-background.jpg';
$memberplex-font-family: Work Sans, -apple-system, BlinkMacSystemFont, sans-serif;

$demo-header-background-url: '/assets/images/demo/header-background.jpeg';
$demo-login-background-url: '/assets/images/demo/login-background.jpg';
$demo-font-family: Work Sans, -apple-system, BlinkMacSystemFont, sans-serif;

$nccer-header-background-url: '/assets/images/nccer/header-background.png';
$nccer-font-family: Noto Sans, -apple-system, BlinkMacSystemFont, sans-serif;

$gamsd-header-background-url: '/assets/images/gamsd/header-background.jpg';
$gamsd-font-family: Montserrat, -apple-system, BlinkMacSystemFont, sans-serif;
$gamsd-heading-font-family: Roboto Slab, -apple-system, BlinkMacSystemFont, sans-serif;

$open-sans-font-family: Open Sans, -apple-system, BlinkMacSystemFont, sans-serif;

$compass-header-background-url: '/assets/images/compass/header-background.jpg';

$sna-font-family: Red Hat Display, -apple-system, BlinkMacSystemFont, sans-serif;
$sna-header-background-url: '/assets/images/sna/header-background.png';

$smacna-font-family: Montserrat, -apple-system, BlinkMacSystemFont, sans-serif;
$smacna-header-background-url: '/assets/images/smacna/header-background.jpg';

$teams-elite-header-background-url: '/assets/images/teamsElite/header-background.jpg';
$teams-elite-font-family: Poppins, -apple-system, BlinkMacSystemFont, sans-serif;
$teams-elite-heading-font-family: Bebas Neue, -apple-system, BlinkMacSystemFont, sans-serif;

$themes: (
    'memberplex': (
        'primary': #5163F8,ch
        'secondary': #F6A148,
        'green': #01D560,
        'red': #CD2F47,
        'sidebar': rgba(#F6A148, 0.8),
        'text': #000000,
        'font-family': $memberplex-font-family,
        'heading-font-family': $memberplex-font-family,
        'login-background': fixed $light-grey url($default-login-background-url) center/cover,
        'header-background': $light-grey url($default-header-background-url) center/cover,
    ),
    'demo': (
        'primary': #4B0082,ch
        'secondary': #fa6f15,
        'green': #01D560,
        'red': #CD2F47,
        'sidebar': rgba(#fa6f15, 0.8),
        'text': #000000,
        'font-family': $demo-font-family,
        'heading-font-family': $demo-font-family,
        'login-background': fixed $light-grey url($demo-login-background-url) center/cover,
        'header-background': $light-grey url($demo-header-background-url) center/cover,
    ),
    'nccer': (
        'primary': $primary-blue,
        'secondary': #FD6A3C,
        'green': $green,
        'red': $dark-red,
        'sidebar': #3F5E9D,
        'text': #717273,
        'font-family': $nccer-font-family,
        'heading-font-family': $nccer-font-family,
        'login-background': fixed #183b66 url($default-login-background-url) center/cover,
        'header-background': #183b66 url($nccer-header-background-url) center/cover,
    ),
    'gamsd': (
        'primary': #20395D,
        'secondary': #F1C81A,
        'green': $light-green,
        'red': $dark-red,
        'sidebar': #2973B4CD,
        'text': #000000,
        'font-family': $gamsd-font-family,
        'heading-font-family': $gamsd-heading-font-family,
        'login-background': fixed $light-grey url($default-login-background-url) center/cover,
        'header-background': $light-grey url($gamsd-header-background-url) 100% center/cover,
    ),
    'compass': (
        'primary': #002248,
        'secondary': #f9423e,
        'green': $bright-green,
        'red': $dark-red,
        'sidebar': #2E80ECCC,
        'text': #59788A,
        'font-family': $open-sans-font-family,
        'heading-font-family': $open-sans-font-family,
        'login-background': fixed $light-grey url($default-login-background-url) center/cover,
        'header-background': $light-grey url($compass-header-background-url) center/cover,
    ),
    'sna': (
        'primary': #5C0A55,
        'secondary': #D71E30,
        'green': $green,
        'red': $dark-red,
        'sidebar': #5C0A55CC,
        'text': #453E51,
        'font-family': $sna-font-family,
        'heading-font-family': $sna-font-family,
        'login-background': fixed #5C0A55 url($default-login-background-url) center/cover,
        'header-background': #5C0A55 url($sna-header-background-url) center/cover,
    ),
    'smacna': (
        'primary': #3C5896,
        'secondary': #E4002B,
        'green': $green,
        'red': $dark-red,
        'sidebar': #E4002B,
        'text': #000000,
        'font-family': $smacna-font-family,
        'heading-font-family': $smacna-font-family,
        'login-background': fixed #5C0A55 url($default-login-background-url) center/cover,
        'header-background': #5C0A55 url($smacna-header-background-url) center/cover,
    ),
    'acs': (
        'primary': #0F2144,
        'secondary': #E91F00,
        'green': $green,
        'red': $dark-red,
        'sidebar': #E91F00,
        'text': #000000,
        'font-family': $open-sans-font-family,
        'heading-font-family': $open-sans-font-family,
        'login-background': fixed $light-grey url($default-login-background-url) center/cover,
        'header-background': #0F2144 center/cover,
    ),
    'teams-elite': (
        'primary': #13647F,
        'secondary': #00AEEF,
        'green': $green,
        'red': $dark-red,
        'sidebar': #00AEEF,
        'text': #000000,
        'font-family': $teams-elite-font-family,
        'heading-font-family': $teams-elite-heading-font-family,
        'login-background': fixed $light-grey url($default-login-background-url) center/cover,
        'header-background': $light-grey url($teams-elite-header-background-url)  center/cover,
    ),
) !default;

$primary-theme-color: null;
$secondary-theme-color: null;
$green-theme-color: null;
$red-theme-color: null;
$primary-text-color: null;
$sidebar-item-background-color: null;
$login-background: null;
$header-background: null;

@mixin themify($disableThemeOption: false) {
  @each $theme, $colors in $themes {
    @if ($disableThemeOption) {
      :global(.theme-#{$theme}) > *:not(:global(.#{$disable-theme-classname})) & {
        $primary-theme-color: map-get($colors, 'primary') !global;
        $secondary-theme-color: map-get($colors, 'secondary') !global;
        $green-theme-color: map-get($colors, 'green') !global;
        $red-theme-color: map-get($colors, 'red') !global;
        $primary-text-color: map-get($colors, 'text') !global;
        $sidebar-item-background-color: map-get($colors, 'sidebar') !global;
        $login-background: map-get($colors, 'login-background') !global;
        $header-background: map-get($colors, 'header-background') !global;

        @content;

        $primary-theme-color: null !global;
        $secondary-theme-color: null !global;
        $primary-text-color: null !global;
        $green-theme-color: null !global;
        $red-theme-color: null !global;
        $sidebar-item-background-color: null !global;
        $login-background: null !global;
        $header-background: null !global;
      }
    } @else {
      :global(.theme-#{$theme}) & {
        $primary-theme-color: map-get($colors, 'primary') !global;
        $secondary-theme-color: map-get($colors, 'secondary') !global;
        $green-theme-color: map-get($colors, 'green') !global;
        $red-theme-color: map-get($colors, 'red') !global;
        $primary-text-color: map-get($colors, 'text') !global;
        $sidebar-item-background-color: map-get($colors, 'sidebar') !global;
        $login-background: map-get($colors, 'login-background') !global;
        $header-background: map-get($colors, 'header-background') !global;

        @content;

        $primary-theme-color: null !global;
        $secondary-theme-color: null !global;
        $green-theme-color: null !global;
        $red-theme-color: null !global;
        $primary-text-color: null !global;
        $sidebar-item-background-color: null !global;
        $login-background: null !global;
        $header-background: null !global;
      }
    }
  }
}
